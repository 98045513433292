import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiCoreService } from './api-core.service';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ItemApiService {
  env = environment.host;
  constructor(
    private apiCoreService: ApiCoreService,
    private localizationService: TranslocoService,
  ) {}

  getMain(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/main',
    );
  }

  getMeass(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
      '/api/' +
      this.localizationService.getActiveLang() +
      '/category/meas',
    );
  }


  getCareer(page: number, limit: number): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/vacancies?page=${page}&limit=${limit}`,
    );
  }
  getNews(page: number, limit: number, filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/news?page=${page}&limit=${limit}` +
        filtersString,
    );
  }
  getNewsById(id: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/news/${id}`,
    );
  }

  getUser(): Observable<any> {
    return this.apiCoreService.get(
      this.env + `/api/login`,
      {},
      new HttpHeaders().append(
        'Authorization',
        'YzjS3Y1PlfrZIjd4053Gk/57jxFJWGMy7zr5kFcBgOQ=',
      ),
    );
  }

  getLastPublic(id?: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/last-three-public-procurement' +
        (id ? '/' + id : ''),
    );
  }

  getLastNinePublic(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/last-nine-public-procurement',
    );
  }

  getPublicById(id: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/public-procurement/${id}`,
    );
  }

  getThreeNews(id?: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/last-three-news' +
        (id ? '/' + id : ''),
    );
  }
  getThreeVacancies(id?: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/last-three-vacancies' +
        (id ? '/' + id : ''),
    );
  }
  getVacancyDetails(id?: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/vacancies' +
        (id ? '/' + id : ''),
    );
  }
  getSinglCareer(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/content/single-career ',
    );
  }

  getFooter(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/content/footer',
    );
  }

  getRequest(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/content/patients-request ',
    );
  }

  getRequestForm(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/forms/patients-form-data',
    );
  }

  sendPatientsRequest(body: any): Observable<any> {
    const data = new FormData();
    Object.keys(body).map((el) => {
      data.append(el, body[el]);
    });
    return this.apiCoreService.post(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/patients-request',
      data,
    );
  }

  newsCareer(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/career-news`,
    );
  }

  getContacts(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/contacts',
    );
  }
  getTeam(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        '/category/team',
    );
  }
  getHeaderItems(): Observable<any> {
    return this.apiCoreService.get(this.env + '/api/menu');
  }
  getPublic(page: number, limit: number): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        '/api/' +
        this.localizationService.getActiveLang() +
        `/category/public-procurement?page=${page}&limit=${limit}`,
    );
  }

  getSuppliersFaq(
    page: number,
    limit: number,
    category?: any,
  ): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-suppliers?page=${page}&limit=${limit}` +
        (category ? '&category=' + category : ''),
    );
  }
  getSuppliersFaqTopThree(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-suppliers/top-three`,
    );
  }

  getSuppliersFaqFilters(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-suppliers/categories`,
    );
  }

  getPatientsFaqFilters(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-patients/categories`,
    );
  }
  getPatientsFaqTopThree(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-patients/top-three`,
    );
  }

  getAnalogs(
    page: number,
    limit: number,
    filters: any[],
    id: string,
  ): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect' || el.type === 'select-search') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/catalog-analogues/${id}?page=${page}&limit=${limit}` +
        filtersString,
    );
  }

  getCatalogs(page: number, limit: number, filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect' || el.type === 'select-search') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/catalog?page=${page}&limit=${limit}` +
        filtersString,
    );
  }

  getCatalogHospitals(
    id: string,
    page: number,
    limit: number,
    filters: any[],
    order?: string,
    sort_by?: string,
  ): Observable<any> {
    let filtersString = '';
    if (order) {
      filtersString += `&order=${order}&sort_by=${sort_by}`;
    } else {
      filtersString += `&order=asc&sort_by=institution`;
    }
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (
          el.type === 'multiselect' ||
          el.type === 'select-search' ||
          el.type === 'multiselect_chips'
        ) {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/catalog-hospitals/${id}?page=${page}&limit=${limit}` +
        filtersString,
    );
  }
  getOrders(page: number, limit: number, filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/order?page=${page}&limit=${limit}` +
        filtersString,
    );
  }

  getOrderFilters(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/orders`,
    );
  }

  getReportFilters(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
      `/api/${this.localizationService.getActiveLang()}/filter-fields/report`,
    );
  }

  getCategoriesFilters(filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else {
          filtersString +=
            '&' + el.key + '[]' + '=' + encodeURIComponent(el.value);
        }
      });
    }
    filtersString = filtersString.replace('&', '?');

    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/prozorro-categories` +
        filtersString,
    );
  }

  getNewsFilters(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/news`,
    );
  }

  getCatalogAnalogFilters(filters: any[], id: string): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect' || el.type === 'select-search') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/analogues?id=${id}` +
        filtersString,
    );
  }

  getCatalogFilters(filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect' || el.type === 'select-search') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    filtersString = filtersString.replace('&', '?');
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/catalog` +
        filtersString,
    );
  }

  getCatalogContentById(id: string): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/catalog/${id}`,
    );
  }
  getHospitalFilters(filters: any[], id: string): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect' || el.type === 'select-search') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/filter-fields/hospitals?id=${id}` +
        filtersString,
    );
  }

  getHospitalHeader(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/forms/hospitals-form-data`,
    );
  }
  getCategoriesHeader(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/forms/prozorro-category-form-data`,
    );
  }

  getAbout(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/about`,
    );
  }

  getHospitals(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/hospitals`,
    );
  }
  getCareerAnticorruption(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/career-anticorruption`,
    );
  }

  search(page: number, limit: number, search: any): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/search?page=${page}&limit=${limit}` +
        (search ? '&keyword=' + search : ''),
    );
  }
  getEmptyCatalog(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/no-info-catalog`,
    );
  }
  getCatalogContent(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/catalog`,
    );
  }

  getActiveCategories(
    page: number,
    limit: number,
    filters: any[],
  ): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else {
          filtersString +=
            '&' + el.key + '[]' + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/prozorro-categories?page=${page}&limit=${limit}` +
        filtersString,
    );
  }

  getPatientsFaq(page: number, limit: number, category?: any): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/faq-patients?page=${page}&limit=${limit}` +
        (category ? '&category=' + category : ''),
    );
  }

  newsAnticorruption(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/news-anticorruption`,
    );
  }
  getVacancy(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/career`,
    );
  }
  getSupply(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/suppliers`,
    );
  }
  getPatients(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/patients`,
    );
  }

  getProzzoroAdd(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/content/prozorro-add-products`,
    );
  }

  getReportCorruption(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/report-corruption`,
    );
  }

  getAnticorruption(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/anticorruption`,
    );
  }

  getProzzoro(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/prozorro`,
    );
  }

  getFilia(): Observable<any> {
    return this.apiCoreService.get(
      this.env +
        `/api/${this.localizationService.getActiveLang()}/category/filia`,
    );
  }
  getReports(page: number, limit: number, filters: any[]): Observable<any> {
    let filtersString = '';
    if (filters?.length) {
      filters.map((el) => {
        if (el.key === 'addData') {
          const date = el.value.split(' - ');
          filtersString +=
            '&dataStart=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          if (date[1]) {
            filtersString +=
              '&dataEnd=' + moment(date[1], 'DD.MM.yyyy').format('yyyy-MM-DD');
          } else {
            filtersString +=
              '&dataEnd=' + moment(date[0], 'DD.MM.yyyy').format('yyyy-MM-DD');
          }
        } else if (el.type === 'multiselect') {
          filtersString += '&' + el.key + '[]=' + el.value;
        } else {
          filtersString += '&' + el.key + '=' + encodeURIComponent(el.value);
        }
      });
    }
    return this.apiCoreService.get(
      this.env +
      `/api/${this.localizationService.getActiveLang()}/category/report?page=${page}&limit=${limit}` +
      filtersString,
    );
  }
}
